import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-teacher-test-table',
    templateUrl: './teacher-test-table.component.html',
    standalone: false
})
export class TeacherTestTableComponent implements OnInit {

	columns = [	{title: 'title', caption: 'title', filter: 'none', width: '40%'},
				{title: 'max_allowed_attempts', caption: 'max-attempts', filter: 'none', width: '15%'},
				{title: 'allow_review', caption: 'allow-reviewing', filter: 'boolean', width: '15%'},
				{title: 'time_limit', caption: 'time-limit', filter: 'none', width: '15%'},
  				{title: 'created_at', caption: 'created-at', filter: 'date', width: '15%'}
  			  ];
  	displayedColumns = ['title', 'max_allowed_attempts', 'allow_review', 'time_limit', 'created_at'];

  	constructor() { }

  	ngOnInit(): void {}

}
