import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-student-test',
    templateUrl: './student-test.component.html',
    styleUrls: ['./student-test.component.css'],
    standalone: false
})
export class StudentTestComponent implements OnInit {
  private testUuid: string|null = '';
  private attemptUuid: string|null = '';
  private dateStart: number = 0;
  private timeLeft: number = 0;
  private timeLimit: number = 0;
  private timer: any;
  public test: any;
  public question: any;
  public testStarted: boolean = false;
  public answerForm = new UntypedFormGroup({});
  public testCompleted: boolean = false;
  public isLoadingResults: boolean = false;
  public timeLeftHour: number = 0;
  public timeLeftMin: number = 0;
  public timeLeftSec: number = 0;

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.testUuid = this.route.snapshot.paramMap.get('uuid');
    this.http.get(`${environment.apiURL}/students/tests/${this.testUuid}/pre_start`).subscribe((response:any)=>{
      this.test = response['data'];
      this.isLoadingResults = false;
    });
  }

  prepData(attempt: any){
    this.attemptUuid = attempt?.uuid;
    if (attempt?.completed){
      this.testCompleted = true;
    } else {
      this.question = attempt?.question;
      let formGroup: any = {};
      if (this.question.type<6){
        formGroup[this.question.uuid] = [''];
      } else {
        this.question.options.forEach((opt:any)=>{
          formGroup[opt.uuid] = [false];
        });
      }
      this.answerForm = this.formBuilder.group(formGroup);
    }
    this.isLoadingResults = false;
  }

  start(){
    this.isLoadingResults = true;
    this.http.post(`${environment.apiURL}/students/tests/attempts`, {test_uuid: this.testUuid}).subscribe((response: any)=>{
      this.prepData(response['data']);
      this.test.attempts_count++;
      this.testStarted = true;
      //only sets the trigger if there is a time limit
      if (this.test.time_limit) {
        this.timeLimit = this.test.time_limit*60;
        this.dateStart = Date.now();
        this.calcTimeDiff();
        this.timer = interval(1000).subscribe(x => {
                        this.calcTimeDiff();
                    });
      }
    });
  }

  nextAnswer(){
    this.isLoadingResults = true;
    let data = {answer: this.answerForm.getRawValue(), attempt_uuid: this.attemptUuid, question_uuid: this.question.uuid};
    this.http.post(`${environment.apiURL}/students/tests/answer`, data, {responseType: 'json'}).subscribe((response:any)=>{
      this.prepData(response['data']);
    });
  }

  calcTimeDiff(){
    this.timeLeft = this.timeLimit - Math.floor((Date.now()-this.dateStart)/1000);
    this.setTimers();
  }

  setTimers(){
    //when it times out then reaches out to the server to save the answers and finish the test
    if (this.timeLeft < 0) {
      this.timeLeft = 0;
      this.http.post(`${environment.apiURL}/students/tests/${this.testUuid}/timeout`, {attempt_uuid: this.attemptUuid}).subscribe((response:any)=>{
        this.prepData(response.data);
        this.timer.unsubscribe();
      });
    }
    this.timeLeftHour = Math.floor(this.timeLeft/3600);
    this.timeLeftMin = Math.floor((this.timeLeft-this.timeLeftHour*3600)/60);
    this.timeLeftSec = this.timeLeft-this.timeLeftHour*3600-this.timeLeftMin*60;
  }

}
