import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.css'],
    standalone: false
})
export class AboutComponent implements OnInit {
  public version: string|null = environment.appVersion;

  constructor(private meta: Meta) { 
    this.meta.addTags([
      {name: 'description', content: 'About Ddolphin'},
      {name: 'author', content: 'Ddolphin'},
      {name: 'keywords', content: 'Education, Ddolphin, Learn, Teach, Social'}
    ]);
  }

  ngOnInit(): void {
  }

}
