/*base modules*/
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';

/*material/design modules*/
import { CdkTableModule } from '@angular/cdk/table';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule as MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule as MatCardModule } from '@angular/material/card';
import { MatChipsModule as MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule as MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule as MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { MatListModule as MatListModule } from '@angular/material/list';
import { MatMenuModule as MatMenuModule } from '@angular/material/menu';
import { MatOptionModule as MatOptionModule } from '@angular/material/core';
import { MatPaginatorModule as MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule as MatRadioModule } from '@angular/material/radio';
import { MatSelectModule as MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule as MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule as MatTabsModule } from '@angular/material/tabs';
import { MatTableModule as MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule as MatTooltipModule } from '@angular/material/tooltip';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ScrollingModule } from '@angular/cdk/scrolling';

/*various modules*/
import { HttpConfigInterceptor } from './interceptors/httpconfig.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

/*components*/
import { AccountRecoveryComponent } from './pages/account-recovery/account-recovery.component';
import { AppComponent } from './app.component';
import { ChipsComponent } from './db/chips/chips.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { FormComponent } from './db/form/form.component';
import { FriendComponent } from './pages/friend/friend.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SearchComponent } from './pages/search/search.component';
import { SignupComponent } from './pages/signup/signup.component';
import { StudentLessonComponent } from './pages/student-lesson/student-lesson.component';
import { TableComponent } from './db/table/table.component';
import { TeacherLessonFormComponent } from './pages/teacher-lesson/teacher-lesson-form.component';
import { TeacherLessonTableComponent } from './pages/teacher-lesson/teacher-lesson-table.component';
import { TeacherTestFormComponent, QuestionModal } from './pages/teacher-test/teacher-test-form.component';
import { TeacherTestTableComponent } from './pages/teacher-test/teacher-test-table.component';
import { StudentTestComponent } from './pages/student-test/student-test.component';
import { StudentTestTableComponent } from './pages/student-test/student-test-table.component';
import { StudentTestReviewComponent } from './pages/student-test/student-test-review.component';
import { AboutComponent } from './pages/about/about.component';
import { PoliciesComponent } from './pages/policies/policies.component';
import { TeacherGradingTableComponent } from './pages/teacher-grading/teacher-grading-table.component';
import { TeacherGradingFormComponent } from './pages/teacher-grading/teacher-grading-form.component';
import { TeacherTestPrintComponent } from './pages/teacher-test/teacher-test-print.component';
import { StudentPerformanceComponent } from './pages/student-performance/student-performance.component';
import { NgChartsModule } from 'ng2-charts';
import { TeacherDashboardComponent } from './pages/teacher-dashboard/teacher-dashboard.component';
import { NetworkComponent } from './pages/network/network.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { MessagingComponent } from './pages/messaging/messaging.component';
import { environment } from 'src/environments/environment';
import { MessagingService } from './services/messaging-service';
import { AsyncPipe } from '@angular/common';
import { EmailVerificationComponent } from './pages/email-verification/email-verification.component';
import { AboutPtComponent } from './pages/about/about-pt.component';
import { PoliciesPtComponent } from './pages/policies/policies-pt.component';

import { TinyMCE as tinymce } from 'tinymce';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM, YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    }
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({ declarations: [
        AccountRecoveryComponent,
        AppComponent,
        ChipsComponent,
        ConfirmationDialogComponent,
        FormComponent,
        FriendComponent,
        HomeComponent,
        LoginComponent,
        PasswordResetComponent,
        ProfileComponent,
        QuestionModal,
        SearchComponent,
        SignupComponent,
        StudentLessonComponent,
        TableComponent,
        TeacherLessonFormComponent,
        TeacherLessonTableComponent,
        TeacherTestFormComponent,
        TeacherTestTableComponent,
        StudentTestComponent,
        StudentTestTableComponent,
        StudentTestReviewComponent,
        AboutComponent,
        PoliciesComponent,
        TeacherGradingTableComponent,
        TeacherGradingFormComponent,
        TeacherTestPrintComponent,
        StudentPerformanceComponent,
        TeacherDashboardComponent,
        NetworkComponent,
        NotificationComponent,
        MessagingComponent,
        EmailVerificationComponent,
        AboutPtComponent,
        PoliciesPtComponent
    ],
    bootstrap: [AppComponent], imports: [AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        EditorModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CdkTableModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatOptionModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSidenavModule,
        MatSortModule,
        MatTabsModule,
        MatTableModule,
        MatToolbarModule,
        MatTooltipModule,
        ScrollingModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right'
        }),
        NgChartsModule], providers: [
        MessagingService, AsyncPipe,
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
