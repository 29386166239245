import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-network',
    templateUrl: './network.component.html',
    styleUrls: ['./network.component.css'],
    standalone: false
})
export class NetworkComponent implements OnInit {

  public tab: number = 0;
  public is_loading: boolean = true;

  public people: any = [];
  public total_count: number = 0;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.loadData(0);
  }

  loadFollowing(page:number){
    this.http.get(`${environment.apiURL}/network/following?page=${page}`).subscribe((response: any)=>{
      this.people = response.data.following;
      this.total_count = response.data.total_count;
      this.is_loading = false;
    });
  }

  loadAwaiting(page:number){
    this.http.get(`${environment.apiURL}/network/awaiting?page=${page}`).subscribe((response: any)=>{
      this.people = response.data.following;
      this.total_count = response.data.total_count;
      this.is_loading = false;
    });
  }

  loadFollowers(page:number){
    this.http.get(`${environment.apiURL}/network/followers?page=${page}`).subscribe((response: any)=>{
      this.people = response.data.followers;
      this.total_count = response.data.total_count;
      this.is_loading = false;
    });
  }

  loadBlocked(page:number){
    this.http.get(`${environment.apiURL}/network/blocked?page=${page}`).subscribe((response: any)=>{
      this.people = response.data.blocked;
      this.total_count = response.data.total_count;
      this.is_loading = false;
    });
  }

  loadRequested(page:number){
    this.http.get(`${environment.apiURL}/network/requested?page=${page}`).subscribe((response: any)=>{
      this.people = response.data.requested;
      this.total_count = response.data.total_count;
      this.is_loading = false;
    });
  }

  loadData(tab: number){
    this.tab = tab;
    this.is_loading = true;
    switch (tab) {
      case 0:
        this.loadFollowing(1);
        break;
      case 1:
        this.loadFollowers(1);
        break;
      case 2:
        this.loadRequested(1);
        break
      case 3:
        this.loadAwaiting(1);
        break;
      case 4:
        this.loadBlocked(1);
        break;
      default:
        break;
    }
  }

  accept(username:string){
    this.http.patch(`${environment.apiURL}/friendship/accept`, {friend_username: username}).subscribe((response: any)=>{
      this.loadRequested(1);
    });
  }

  deny(username:string){
    this.http.patch(`${environment.apiURL}/friendship/deny`, {friend_username: username}).subscribe((response: any)=>{
      this.loadRequested(1);
    });
  }

}
