import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SignupValidator } from 'src/app/validators/signup.validators';
import { AuthService } from '../../services/auth.service';

import { environment } from '../../../environments/environment';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.css'],
    standalone: false
})
export class SignupComponent implements OnInit {

	form = new UntypedFormGroup({
      username: new UntypedFormControl('', [Validators.required, Validators.minLength(8), SignupValidator.usernameFormatValidator]),
			name: new UntypedFormControl('', [Validators.required]),
			surname: new UntypedFormControl('', [Validators.required]),
			email: new UntypedFormControl('', [Validators.required, SignupValidator.emailTrimValidator]),
			password: new UntypedFormControl('', [Validators.required, SignupValidator.passwordStrengthValidator]),
			password_confirmation: new UntypedFormControl('')
		}, {
			validators: SignupValidator.passwordsMatch()
	});

  success: boolean = false;
  error: string = '';
  public breakpoint: number = 1;

  constructor(private http: HttpClient, private toastr: ToastrService, private meta: Meta, private authService: AuthService, 
              private translate: TranslateService, private matIconReg: MatIconRegistry, private domSanitizer: DomSanitizer) { 
    this.meta.addTags([
      {name: 'description', content: 'Ddolphin Signup Page'},
      {name: 'author', content: 'Ddolphin'},
      {name: 'keywords', content: 'Education, Ddolphin, Learn, Teach, Social'}
    ]);

    this.matIconReg.addSvgIcon("google", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/btn_google_dark_normal_ios.svg"));
    this.matIconReg.addSvgIcon("google_disabled", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/btn_google_light_disabled_ios.svg"));
  }

  ngOnInit(): void {
    this.breakpoint = (window.innerWidth <= 780) ? 1 : 2;
  }

  onSubmit(){
    if (!this.form.valid) {
      this.error = 'validation';
      return;
    }

    this.form.addControl('language', new UntypedFormControl(this.translate.currentLang));
    this.http.post(`${environment.apiURL}/users`, this.form.getRawValue())
      .subscribe({
        next: result=>{
          this.completeSignUp();
        }, 
        error: error=>{
          switch (error.status) {
            case 409:
              this.error = 'duplicated';
              break;
            case 422:
              this.error = 'validation';
              break;
            default:
              this.error = 'unknown';
              break;
          }
        }
      });
  }

  completeSignUp(){
    this.success = true;
    this.error = '';
    this.toastr.success(this.translate.instant('data-saved'), this.translate.instant('success'));
  }

  async googleAuth(){
		let user = await GoogleAuth.signIn();
		await this.http.post(`${environment.apiURL}/users/oauth`, { user: user }).subscribe({
			next: (response: any) => {
				this.completeSignUp();
			},
			error: err => {
        switch (err.status) {
          case 409:
            this.error = 'duplicated';
            break;
          case 422:
            this.error = 'validation';
            break;
          default:
            this.toastr.error(this.translate.instant('google-fail'), `${this.translate.instant('error')}!`);
            this.error = 'unknown';
            break;
        }
			}
		});
	}

  checkUsernameAvailability(){
    const username = this.form.controls['username'];
    if (username.value.trim().length<3)
      return;

    this.http.get(`${environment.apiURL}/username-availability?username=${username.value}`)
      .subscribe((result: any)=>{
        //if data is null or false from the server, then username is not available and sets error to already alreadyTaken
        //if comes true, it means it's available and sets errors to null
        username.setErrors(result['data'] ? null : {alreadyTaken: true});
      });   
  }

  onResize(event: any) {
    this.breakpoint = (event.target.innerWidth <= 780) ? 1 : 2;
  }
}
