import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    standalone: false
})
export class LoginComponent implements OnInit {

  form = new UntypedFormGroup({
    email: new UntypedFormControl('', Validators.required),
    password: new UntypedFormControl('', Validators.required)
  });
  isAuthenticated: boolean = false;
  public loginInvalid: boolean = false;
  public breakpoint: number = 1;

  constructor(private formBuilder: UntypedFormBuilder, private router: Router, public authService: AuthService, 
              private meta: Meta, private translate: TranslateService, private matIconReg: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.meta.addTags([
      {name: 'description', content: 'Ddolphin Login Page'},
      {name: 'author', content: 'Ddolphin'},
      {name: 'keywords', content: 'Education, Ddolphin, Learn, Teach, Social'}
    ]);
    
    this.matIconReg.addSvgIcon("google", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/btn_google_dark_normal_ios.svg"));
  }

  async ngOnInit() {
    this.breakpoint = (window.innerWidth <= 780) ? 1 : 2;
    this.authService.signToken();
    if (this.authService.isAuthenticated) 
      this.router.navigate(['/']);
  }

  async onSubmit() {
    this.loginInvalid = false;
    this.isAuthenticated = false;
    if (this.form.valid)
      await this.authService.login(this.form.value.email, this.form.value.password)
  }

  onResize(event: any) {
    this.breakpoint = (event.target.innerWidth <= 780) ? 1 : 2;
  }

  async googleAuth(){
    await this.authService.googleAuth();
  }

  getPoliciesLink(withCookies:boolean){
    let link = `/policies/${this.translate.currentLang=='en' ? '' : this.translate.currentLang}`;
    return withCookies ? `${link}#cookies`: link;
  }

}