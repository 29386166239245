import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-student-test-table',
    templateUrl: './student-test-table.component.html',
    styleUrls: ['./student-test-table.component.css'],
    standalone: false
})
export class StudentTestTableComponent implements OnInit {

  columns = [	{title: 'title', caption: 'title', filter: 'none', width: '40%'},
              {title: 'graded', caption: 'already-graded', filter: 'boolean', width: '20%'},
              {title: 'marks', caption: 'marks-of-100', filter: 'none', width: '20%'},
              {title: 'created_at', caption: 'created-at', filter: 'date', width: '20%'}
            ];
  
  displayedColumns = ['title', 'graded', 'marks', 'created_at'];

  constructor() { }

  ngOnInit(): void {
  }

}
