import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-teacher-dashboard',
    templateUrl: './teacher-dashboard.component.html',
    styleUrls: ['./teacher-dashboard.component.css'],
    standalone: false
})
export class TeacherDashboardComponent implements OnInit {

  public showPopularLessons: boolean = false;
  public popularLessonsData: any = {};
  public likedLessonsData: any = {};
  public showLikedLessons: boolean = false;
  public displayedColumns: string[] = ['title'];

  public showGradedTests: boolean = false;
  public gradedTestsData: ChartConfiguration['data'] = {datasets: []};
  public gradedTestsOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      yAxis: {
        ticks: {
          precision: 0
        }
      }
    }
  }

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  loadPopularLessons(){
    this.http.get(`${environment.apiURL}/teachers/reports/popular-lessons`).subscribe((response:any)=>{
      this.popularLessonsData = response.data;
      this.showPopularLessons = true;
    });
  }

  loadLikedLessons(){
    this.http.get(`${environment.apiURL}/teachers/reports/liked-lessons`).subscribe((response:any)=>{
      this.likedLessonsData = response.data;
      this.showLikedLessons = true;
    });
  }

  loadGradedTests(){
    this.http.get(`${environment.apiURL}/teachers/reports/graded-tests`).subscribe((response:any)=>{
      this.gradedTestsData.datasets = response.data.datasets;
      this.gradedTestsData.labels = response.data.labels;
      this.showGradedTests = true;
    });
  }

}
