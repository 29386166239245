<mat-spinner *ngIf="is_loading" [diameter]="50"></mat-spinner>
<mat-tab-group (selectedIndexChange)="loadData($event)" *ngIf="!is_loading">
  <mat-tab [label]="'profile'|translate">
    <mat-card class="mat-elevation-z8">
  		<mat-card-header>
        <div class="avatar">
          <img mat-card-avatar [src]="'data:image/jpg;base64,' +friend?.profile_picture" *ngIf="friend?.profile_picture!=null">
          <div class="avatar-empty" *ngIf="friend?.profile_picture==null">{{ friend?.name.charAt(0) }}{{ friend?.surname.charAt(0) }}</div>
        </div>
        <span class="title">
          <mat-card-title>{{ friend?.name }} {{ friend?.surname }}</mat-card-title>
          <mat-card-subtitle>&#64;{{ friend?.username }}</mat-card-subtitle>
        </span>
      </mat-card-header>
      <mat-card-content [innerHtml]="friend?.about_me">
      </mat-card-content>
      <mat-card-actions>
        <div class="vivid-theme follow-buttons">
          <button type="button" mat-raised-button color="primary" *ngIf="!friend?.is_added" (click)="follow(friend?.uuid)">
            <mat-icon>person_add</mat-icon> {{'follow'|translate}}
          </button>
          <button type="button" mat-raised-button color="accent" *ngIf="friend?.is_added&&friend?.accepted_at==null" (click)="unfollow(friend?.uuid)">
            <mat-icon>pending_actions</mat-icon> {{'awaiting-approval'|translate}}
          </button>
        </div>
        <div class="dark-theme">
          <button type="button" mat-raised-button color="primary" *ngIf="friend?.is_added&&friend?.accepted_at!=null" (click)="unfollow(friend?.uuid)">
            <mat-icon>how_to_reg</mat-icon> {{'following'|translate}}
          </button>
          <button type="button" mat-raised-button color="warn" (click)="block(friend?.uuid)" *ngIf="!friend?.is_blocked">
            <mat-icon>lock</mat-icon> {{'block'|translate}}
          </button>
          <button type="button" mat-raised-button color="accent" (click)="unblock(friend?.uuid)" *ngIf="friend?.is_blocked">
            <mat-icon>lock_open</mat-icon> {{'unblock'|translate}}
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </mat-tab>
  <mat-tab [label]="'lessons'|translate">
    <mat-card class="mat-elevation-z8 card-lesson" *ngFor="let lesson of lessons; index as i;">
      <a [routerLink]="(friend?.is_added && friend?.accepted_at!=null)||lesson?.privacy==1 ? ['/student/lesson/'+lesson.uuid] : []">
        <mat-card-header>
          <span class="title title-retract">
            <mat-card-title>{{ lesson.title }}</mat-card-title>
            <mat-card-subtitle>{{'published-at'|translate}} {{ lesson?.created_at | date:'MMM d, y, HH:mm' }}</mat-card-subtitle>
          </span>
        </mat-card-header>
        <mat-card-content [innerHtml]="(lesson.lesson.substring(0, 250) | translate) + '...'"></mat-card-content>
      </a>
    </mat-card>
  </mat-tab>
  <mat-tab [label]="'tests'|translate">
    <mat-card class="mat-elevation-z8 card-lesson" *ngFor="let test of tests; index as i;">
      <a [routerLink]="['/student/test/'+test.uuid]">
        <mat-card-header>
          <span class="title title-retract">
            <mat-card-title>{{ test.title }}</mat-card-title>
            <mat-card-subtitle>{{'published-at'|translate}} {{ test?.created_at | date:'MMM d, y, HH:mm' }}</mat-card-subtitle>
          </span>
        </mat-card-header>
        <mat-card-content>
          {{'max-attempts'|translate}}: {{ test.max_allowed_attempts }} <br/>
          {{'allow-reviewing'|translate}}: {{ test.allow_review==0 }}
        </mat-card-content>
      </a>
    </mat-card>  	
  </mat-tab>
</mat-tab-group>