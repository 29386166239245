<mat-spinner *ngIf="isLoadingResults" [diameter]="50"></mat-spinner>
<div class="mat-elevation-z8">
	<mat-card class="example-card">
	  <mat-card-header>
	    <mat-card-title>{{ attempt?.test.title }}</mat-card-title>
	    <mat-card-subtitle>
	    	{{'completed-by'|translate}} &#64;{{ attempt?.student.username }}, {{ attempt?.student.name }} {{ attempt?.student.surname }} {{'at'|translate}} {{ attempt?.completed_at | date:'MMM d, y, HH:mm' }}
	    </mat-card-subtitle>
	  </mat-card-header>
	  <mat-card-content>
        <p>
            {{'current-grade'|translate}}: <b>{{ attempt?.grade }}%</b>  
        </p>
	  </mat-card-content>
	  <mat-card-footer>
		<div class="form-field">
			<mat-form-field class="full-width-input">
				<textarea matInput [placeholder]="'write-a-note'|translate" rows="5" [(ngModel)]="note"></textarea>
				<mat-hint>{{'save-note-tip'|translate}}...</mat-hint>    					
			</mat-form-field>
		</div>
		<button mat-raised-button color="primary" (click)="saveNote()"><mat-icon>save</mat-icon> {{'save'|translate}}</button>
	  </mat-card-footer>
	</mat-card>
</div>
<div class="mat-elevation-z8" *ngIf="questions">
	<mat-card *ngFor="let question of questions">
		<mat-card-header>
			<mat-card-title>
				{{ question.question }}
			</mat-card-title>
			<mat-card-subtitle>
				<span *ngIf="question.type<5">
                    {{'weight'|translate}}: {{ question.answer.weight }} / {{'marks'|translate}}:
                    <input type="number" step="1" min="0" max="100" [value]="question.answer.marks" id="answer_{{question.answer.uuid}}">%
                    <button mat-icon-button (click)="editGrade(question.answer.uuid)"><mat-icon class="small-icon">save</mat-icon></button>
                </span>
                <span *ngIf="question.type==5">{{'weight'|translate}}: {{ question.answer.weight }} / {{'marks'|translate}}: {{ question.answer.marks }}%</span>
				<span *ngIf="question.type==6">{{'weight'|translate}}: {{ question.weight }} / {{'marks'|translate}}: {{ question.marks }}%</span>                
			</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<div *ngIf="question.type<5">
				{{'answer'|translate}}:
				<p>
					<b>{{ question.answer.answer }}</b>
				</p>
			</div>
            <div *ngIf="question.type==5">
                <p *ngFor="let option of question.options">
                    <mat-icon class="align-middle" *ngIf="!option.selected">radio_button_unchecked</mat-icon>
					<mat-icon class="align-middle" *ngIf="option.selected">radio_button_checked</mat-icon>
					{{ option.answer }}
				</p>
            </div>
			<div *ngIf="question.type==6">
				<p *ngFor="let option of question.options">
					<mat-icon class="align-middle" *ngIf="option.answer.answer!='1'">check_box_outline_blank</mat-icon>
					<mat-icon class="align-middle" *ngIf="option.answer.answer=='1'">check_box</mat-icon>
                    {{ option.option }}
                </p>
			</div>
		</mat-card-content>
	</mat-card>
</div>