import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-student-performance',
    templateUrl: './student-performance.component.html',
    styleUrls: ['./student-performance.component.css'],
    standalone: false
})
export class StudentPerformanceComponent implements OnInit {

  public showUsageData: boolean = false;
  public usageData: ChartConfiguration['data'] = {datasets: []};

  public showTestMarks: boolean = false;
  public marksData: ChartConfiguration['data'] = {datasets: []};

  public showQuestionTypes: boolean = false;
  public questionTypesData: ChartConfiguration['data'] = {datasets: []};

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  loadUsageData(){
    this.http.get(`${environment.apiURL}/students/reports/usage`).subscribe((response:any)=>{
      this.usageData.datasets.push({ data: response.data.dataset});
      this.usageData.labels = response.data.labels;
      this.showUsageData = true;
    });
  }

  loadMarksData(){
    this.http.get(`${environment.apiURL}/students/reports/marks`).subscribe((response:any)=>{
      this.marksData.datasets.push({ 
        data: response.data.data, 
        label: 'Marks', 
        backgroundColor: 'rgba(148,159,177,0.2)',
        borderColor: 'rgba(148,159,177,1)',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin'
      });
      this.marksData.labels = response.data.labels;
      this.showTestMarks = true;
    });
  }

  loadQuestionTypes(){
    this.http.get(`${environment.apiURL}/students/reports/question-types`).subscribe((response:any)=>{
      this.questionTypesData.datasets.push({ 
        data: response.data.data, 
        backgroundColor: 'rgba(134,199,243,255)',
        label: 'Marks by Question Type'
      });
      this.questionTypesData.labels = response.data.labels;
      this.showQuestionTypes = true;
    });
  }

}
