import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-teacher-grading-table',
    templateUrl: './teacher-grading-table.component.html',
    standalone: false
})
export class TeacherGradingTableComponent implements OnInit {
  columns = [	{title: 'title', caption: 'test-title', filter: 'none', width: '20%'},
              {title: 'name', caption: "student-name", filter: 'none', width: '20%'},
              {title: 'surname', caption: "student-surname", filter: 'none', width: '20%'},
              {title: 'username', caption: "student-username", filter: 'none', width: '15%'},
  				    {title: 'completed_at', caption: 'completed-at', filter: 'date', width: '12%'},
              {title: 'graded_at', caption: 'graded-question-mark', filter: 'dateOrText', width: '13%'},
  			  ];
  displayedColumns = ['title', 'name', 'surname', 'username', 'completed_at', 'graded_at'];

  constructor() { }

  ngOnInit(): void {
  }

}
