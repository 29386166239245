import { ActivatedRoute } from '@angular/router';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ConfirmationDialogModel, ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { HttpClient } from '@angular/common/http';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-teacher-test-form',
    templateUrl: './teacher-test-form.component.html',
    styleUrls: ['./teacher-test-form.component.css'],
    standalone: false
})
export class TeacherTestFormComponent implements OnInit {

	formItems = [{
    label: 'lessons',
    placehoder: 'search-your-lessons',
    type: 'chip',
    getUrl: 'teachers/tests/lessons',
    postUrl: 'teachers/tests/lessons',
    attachMessage: 'lesson-attached-to-test',
    detachMessage: 'lesson-detached-from-test'
  }, {
      label: 'privacy',
      name: 'privacy',
      type: 'select',
      required: true,
      defaultValue: 1,
      options: [{
          value: 1,
          text: 'anyone-can-do-test'
        }, {
          value: 2,
          text: 'only-students-can-do-test'
        }
      ]
    },{
      label: 'title',
      name: 'title',
      type: 'input',
      required: true,
      placeholder: 'test-title',
      autocomplete: 'off'
    }, {
      label: 'max-attempts',
      name: 'max_allowed_attempts',
      type: 'number',
      required: true,
      placeholder: 'max-attempts',
      autocomplete: 'off',
      min: 0
    },{
      label: 'time-limit-hint',
      name: 'time_limit',
      type: 'number',
      required: false,
      placeholder: 'time-limit-hint',
      autocomplete: 'off',
      min: 0
    },
    {
      label: 'allow-students-review',
      name: 'allow_review',
      type: 'select',
      required: true,
      defaultValue: 0,
      options: [{
          value: 0,
          text: 'no-review-opt'
        }, {
          value: 1,
          text: 'yes-review-opt'
        }
      ]
    }];
	formOptions = {
		url: '/teachers/tests/',
		paramField: 'uuid',
		title: 'test-form',
		deleteConfirmation: 'confirm-remove-test',
		returnLink: '/teacher/tests/',
		editLink: '/teacher/tests/',
		saveMessage: 'test-saved',
		deleteMessage: 'test-removed',
    saveButtonCaption: 'next',
    saveButtonIcon: 'arrow_forward',
    extraButtons: [
      {
        caption: 'print',
        icon: 'print',
        link: '/teacher/tests/print/{uuid}',
        color: 'primary'
      }
    ]
	};

  public uuid: string|null = null;
  public isLoading: boolean = false;
  questions: any;

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private http: HttpClient, 
            private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    if (this.uuid)
      this.loadQuestions();
  }

  loadQuestions(){
    this.http.get(`${environment.apiURL}/teachers/tests/${this.uuid}/questions`)
        .subscribe((response: any)=>{
          this.questions = response['data'];
          this.isLoading = false;
        });
  }

  public addQuestion(){
    const questionModal = this.dialog.open(QuestionModal, {data:  {uuid: '', testUuid: this.uuid}, width: "90%", height: "80%"});
    questionModal.afterClosed().subscribe(result=>this.loadQuestions());
  }

  updateSorting(e: any){
    const uuid = this.questions[e.previousIndex].uuid;
    this.http.patch(`${environment.apiURL}/teachers/tests/${this.uuid}/questions/${uuid}/move/`, {newPosition: e.currentIndex})
      .subscribe(response=>{
        this.toastr.success('Sroting Saved', 'Success');
        moveItemInArray(this.questions, e.previousIndex, e.currentIndex);
      });
  }

  editQuestion(uuid: string){
    const questionModal = this.dialog.open(QuestionModal, {data:  {uuid: uuid, testUuid: this.uuid}, width: "90%", height: "80%"});
    questionModal.afterClosed().subscribe(result=>this.loadQuestions());
  }

  removeQuestion(uuid: string){
    const dialogData = new ConfirmationDialogModel(this.translate.instant('confirm-action'), this.translate.instant('confirm-remove-question'));
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: "70%",
      data: dialogData,
      backdropClass: 'backdrop-background',
      hasBackdrop: true
    });
    dialogRef.updatePosition({top: "10%"});

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        //if clicked yes, then attempts a delete request to the backend
        this.http.delete(`${environment.apiURL}/teachers/tests/${this.uuid}/questions/${uuid}`, {responseType: 'json'})
        .subscribe(response=>{
          //if deleted redirects back to the table
          this.loadQuestions();
          this.toastr.success(this.translate.instant('question-removed'), this.translate.instant('success'));
        })
      } else 
        this.toastr.warning(this.translate.instant('action-cancelled'), this.translate.instant('no-action'));
    });
  }

}

@Component({
    selector: './teacher-test-question',
    templateUrl: './teacher-test-question.html',
    styleUrls: ['./teacher-test-question.css'],
    standalone: false
})
export class QuestionModal implements OnInit{

  questionForm = new UntypedFormGroup({
      question: new UntypedFormControl(''),
      weight: new UntypedFormControl(1),
      type: new UntypedFormControl(),
      answer: new UntypedFormControl(''),
      options: this.formBuilder.array([])
    });
  options: string[] = [''];
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  constructor(  private formBuilder: UntypedFormBuilder, private http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: QuestionModalData, 
                private toastr: ToastrService, public dialogRef: MatDialogRef<QuestionModal>){}

  ngOnInit():void{
    if (this.data.uuid) {
      this.loadOptions();
    }
  }  

  loadOptions(){
    this.isLoading = true;
    this.http.get(`${environment.apiURL}/teachers/tests/${this.data.testUuid}/questions/${this.data.uuid}`)
        .subscribe((response: any)=>{
          this.questionForm.patchValue(response['data']);
          if (Array.isArray(response['data'].options))
            response['data'].options.forEach((option: any)=>{
              this.addOption(option.answer, option.is_true);
            })
          
          this.isLoading = false;
        });
  }

  createOption(answer: UntypedFormControl, isTrue: boolean){
    if (isTrue==null)
      isTrue = false;
    return this.formBuilder.group({
      answer: new UntypedFormControl(answer),
      is_true: new UntypedFormControl(isTrue)
    });
  }

  get optionsFormArray() {
    return (<UntypedFormArray>this.questionForm.get('options'));
  }

  addOption(answer: UntypedFormControl, isTrue: boolean) {
    this.optionsFormArray.push(this.createOption(answer, isTrue));

  }

  removeOption(index: number){
    this.optionsFormArray.removeAt(index);
  }

  saveQuestion(){
    this.isSaving = true;
    const request = this.data.uuid ?
                this.http.put(`${environment.apiURL}/teachers/tests/${this.data.testUuid}/questions/${this.data.uuid}`, JSON.stringify(this.questionForm.value)) :
                this.http.post(`${environment.apiURL}/teachers/tests/${this.data.testUuid}/questions/`, JSON.stringify(this.questionForm.value));
    request.subscribe({
      next: (response: any)=>{
        this.toastr.success('Question Saved', 'Success');
        this.loadOptions();
        this.dialogRef.close();
        this.isSaving = false;
      }, error: err=>{
        this.isSaving = false;
      }
    });
  }

  //rearranges both arrays
  updateOptionsSorting(e: any){
    //this array is what the user sees
    moveItemInArray(this.optionsFormArray.controls, e.previousIndex, e.currentIndex);
    //this is the actual array that will be passed to the backend
    moveItemInArray(this.questionForm.value['options'], e.previousIndex, e.currentIndex);
  }
}

export interface QuestionModalData{
  uuid: string;
  testUuid: string;
}