<mat-spinner *ngIf="isLoadingResults" [diameter]="50"></mat-spinner>
<div class="mat-elevation-z8">
	<mat-card class="example-card">
	  <mat-card-header>
	    <mat-card-title>{{ attempt?.test.title }}</mat-card-title>
	    <mat-card-subtitle>
	    	{{'published-by'|translate}} &#64;{{ attempt?.test.teacher.username }}, {{ attempt?.test.teacher.name }} {{ attempt?.test.teacher.surname }} {{'at'|translate}} {{ attempt?.test.created_at | date:'MMM d, y, HH:mm' }}
	    </mat-card-subtitle>
	  </mat-card-header>
	  <mat-card-content>
        <p>
            {{'you-completed-attempt'|translate}} {{ attempt?.created_at | date:'MMM d, y, HH:mm' }} {{'you-achieved'|translate}}<b> {{ attempt?.grade }}%</b>
        </p>
		<p *ngIf="!attempt?.graded_at">
			{{'test-not-graded-message'|translate}}
		</p>
		<p *ngIf="!questions">
			{{'reviews-not-allowed-message'|translate}}
		</p>
	  </mat-card-content>
	</mat-card>
</div>
<div class="mat-elevation-z8" *ngIf="questions">
	<mat-card *ngFor="let question of questions">
		<mat-card-header>
			<mat-card-title>
				{{ question.question }}
			</mat-card-title>
			<mat-card-subtitle>
				<span *ngIf="question.type<6">{{'weight'|translate}}: {{ question?.answer?.weight }} / {{'marks'|translate}}: {{ question?.answer?.marks }}%</span>
				<span *ngIf="question.type==6">{{'weight'|translate}}: {{ question?.weight }} / {{'marks'|translate}}: {{ question?.marks }}%</span>
			</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<div *ngIf="question.type<5">
				{{'your-answer'|translate}}
				<p>
					<b>{{ question?.answer?.answer }}</b>
				</p>
			</div>
            <div *ngIf="question.type==5">
                <p *ngFor="let option of question.options">
                    <mat-icon class="align-middle" *ngIf="!option.selected">radio_button_unchecked</mat-icon>
					<mat-icon class="align-middle" *ngIf="option.selected">radio_button_checked</mat-icon>
					{{ option.answer }}
				</p>
            </div>
			<div *ngIf="question.type==6">
				<p *ngFor="let option of question.options">
					<mat-icon class="align-middle" *ngIf="option.answer.answer!='1'">check_box_outline_blank</mat-icon>
					<mat-icon class="align-middle" *ngIf="option.answer.answer=='1'">check_box</mat-icon>
                    {{ option.option }}
                </p>
			</div>
		</mat-card-content>
	</mat-card>
</div>