import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { YoutubeValidator } from 'src/app/validators/youtube.validators';

@Component({
    selector: 'app-teacher-lesson-form',
    templateUrl: './teacher-lesson-form.component.html',
    styleUrls: ['./teacher-lesson-form.component.css'],
    standalone: false
})
export class TeacherLessonFormComponent implements OnInit {

  formItems = [{
    label: 'tests',
    placehoder: 'search-your-tests',
    type: 'chip',
    getUrl: 'teachers/lessons/tests',
    postUrl: 'teachers/lessons/tests',
    attachMessage: 'test-attached-to-lesson',
    detachMessage: 'test-detached-from-lesson'
  }, {
      label: 'privacy',
      name: 'privacy',
      type: 'select',
      required: true,
      defaultValue: 1,
      options: [{
          value: 1,
          text: 'anyone-can-subscribe'
        }, {
          value: 2,
          text: 'only-students-can-subscribe'
        }
      ]
    }, {
      label: 'title',
      name: 'title',
      type: 'input',
      required: true,
      placeholder: 'lesson-title',
      autocomplete: 'off'
    }, 
    {
      label: 'youtube-link',
      name: 'video_url',
      type: 'input',
      required: false,
      placeholder: 'link-to-youtube',
      autocomplete: 'off',
      validators: [YoutubeValidator.urlValidator]
    }, {
      label: 'lesson',
      name: 'lesson',
      type: 'quill',
      required: true,
      placeholder: 'lesson-placeholder',
      hint: 'lesson-hint'
    }
  ];
  formOptions = {
    url: '/teachers/lessons/',
    paramField: 'uuid',
    title: 'Lesson Form',
    deleteConfirmation: "confirm-remove-lesson",
    returnLink: '/teacher/lessons/',
    editLink: '/teacher/lessons/',
    saveMessage: 'Lesson Saved!',
    deleteMessage: 'Lesson Removed!'
  };


  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }


}
