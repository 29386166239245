import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { MessagingService } from './services/messaging-service';
import { PushAndroidService } from './services/push-android.service';
import { Meta } from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { App } from '@capacitor/app';
import { Location } from '@angular/common';

const platform = Capacitor.getPlatform();

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent implements OnInit{
  title = 'Ddolphin';
  public lang: string = 'en';
  private allowedLangs = ['en', 'pt'];

  constructor(private router: Router, public authService: AuthService, 
              private msgService: MessagingService, private androidPush: PushAndroidService, private meta: Meta,
              private translate: TranslateService, private location: Location) {
    this.meta.addTags([
      {name: 'description', content: 'Ddolphin Education'},
      {name: 'author', content: 'Ddolphin'},
      {name: 'keywords', content: 'Education, Ddolphin, Learn, Teach, Social'}
    ]);
    let navLang = navigator.language.substring(0, 2);
    this.lang = this.allowedLangs.includes(navLang) ? navLang : 'en';
    translate.addLangs(this.allowedLangs);
    translate.setDefaultLang('en');
    translate.use(this.lang);

    App.addListener('backButton', ({canGoBack})=>{
      if (canGoBack)
        this.location.back();
      else
        if (confirm(this.translate.instant('confirm-exit')))
          App.exitApp();
    });
  }

  async ngOnInit() {
    if (platform=='android') {
      this.androidPush.requestPermission();
      this.androidPush.receiveMessage();
    } else if (platform=='web') {
      this.msgService.requestPermission();
      this.msgService.receiveMessage();
    }
  }

  logout(){
  	this.authService.logout(true);
  }

  getUserName(){
    if (this.authService.user)
      return this.authService.user.name;
  }

  getNotificationsCount(){
    if (this.authService.user)
      return this.authService.user.notification_count>0 ? this.authService.user.notification_count : '' ;

    return '';
  }

  getMessagesCount(){
    if (this.authService.user)
      return this.authService.user.messages_count>0 ? this.authService.user.messages_count : '' ;
  }

  isAuthenticated(){
    if (!this.authService.isAuthenticated)
      return false;

    if (this.authService.user) {
      this.lang = this.authService.user.language;
      this.translate.use(this.allowedLangs.includes(this.lang) ? this.lang : 'en');
    }
    return true;
  }

  search(val: string){
    const search = val.trim();
    if (search)
      this.router.navigate([`/search/${search}`]);
  }

  changeLanguage(lang: string){
    this.translate.use(lang);
    this.lang = lang;
  }

}
