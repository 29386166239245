// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { appVersion } from '../version';

export const environment = {
  production: false,
  apiURL: 'https://api.ddolphin.com/api',
  appVersion: appVersion,
  firebase: {
    apiKey: "AIzaSyDzzZCocBjGdwhOZjWLsIi-ZS4YvXJH--Q",
    authDomain: "ddolphin-2bc56.firebaseapp.com",
    projectId: "ddolphin-2bc56",
    storageBucket: "ddolphin-2bc56.appspot.com",
    messagingSenderId: "413797760839",
    appId: "1:413797760839:web:87660ac0fcfb6bef2ed662",
    measurementId: "G-Z0K6M3G2RF"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
