import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { catchError, map, startWith, switchMap} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';

import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

/*
Generates a material table with data provided by a server
the server url is composed by the environment.apiURL + the url variable that is passed to the directive
the parameters to the server are: sort, direction, page and pageSize which are kinda self explanatory
the expected return data is an object containing an attribut called data, eg: {data: [], stats: 'success'}
the data atribute must contain two elements: items[] and total_count: nummber

*/

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.css'],
    standalone: false
})
export class TableComponent implements AfterViewInit {

  	@Input() displayedColumns: string[]= [];
  	@Input() columns: Object[] = [];
  	@Input() pageSize: number = 10;
    @Input() url: string = '';
    @Input() title: string = 'Welcome';
    @Input() sortColumn: string = 'id';
    @Input() sortDirection: string = 'asc';
    @Input() formLink: string = '';
    @Input() editParam: string = '';
    @Input() hideSearch: boolean = false;
    @Input() hideAddButton: boolean = false;
  	database: DataBase | null = null;
  	dataSource: any[] = [];
  	resultsLength = 0;
  	isLoadingResults = true;
    searchVal: string = '';

  	@ViewChild(MatPaginator) paginator:any = MatPaginator;
  	@ViewChild(MatSort) sort = new MatSort();

  	constructor(private http: HttpClient, private translate: TranslateService) { }

  	genRowValue(val: any, format: string){  
      switch (format) {
        case "date":
          let date = new Date(val);  
          return date.toLocaleDateString("en-UK");
        case "object.title":
          return val.title;
        case "boolean":
          return (val==true) || (val==1);
        case "dateOrText":
          let date2 = new Date(val);
          let text = date2.toLocaleDateString("en-UK");
          return text=="Invalid Date" ? val : text;
        default:
          return val;
      }
  	}

  	ngAfterViewInit() {
  		this.database = new DataBase(this.http);

  		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

      this.load();  		
  	}

    //don't pass search if needed to load the entire table
    load(search: string|null = null){
      merge(this.sort.sortChange, this.paginator.page)
          .pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                //if user is searching, returns getFilteredData, if not return getTableData
                return search ?
                        this.database!.getFilteredData(this.url, search) :
                        this.database!.getTableData(this.url, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.pageSize);
            }),
            map((data: any) => {
                // Flip flag to show that loading has finished.
                this.isLoadingResults = false;
                this.resultsLength = data.data['total_count'];

                return data.data;
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
          )
          .subscribe((data: any) => {
            this.dataSource = data['items'];
          });
    }

    search() {
      let search = this.searchVal.trim();
      //only searches if user has typed 3 or more letters
      if (search.length>2)
        this.load(search);

      //if user emptied the filed, then resets the search
      if (search.length==0)
        this.clearSearch();
    }

    clearSearch(){
      this.searchVal = '';
      this.load();
    }

}

export interface DdolphinApi {
  data: [];
  total_count: number;
}

export class DataBase {
  constructor(private _httpClient: HttpClient) {}

  getTableData(url: string, sort: string, direction: string, page: number, pageSize: number): Observable<DdolphinApi> {
    const href = environment.apiURL+url;
    const requestUrl =
        `${href}?sort=${sort}&direction=${direction}&page=${page + 1}&pageSize=${pageSize}`;

    return this._httpClient.get<DdolphinApi>(requestUrl);
  }

  getFilteredData(url: string, search: string): Observable<DdolphinApi>{
    const href = environment.apiURL+url;
    const requestUrl =
        `${href}?search=${search}`;

    return this._httpClient.get<DdolphinApi>(requestUrl); 
  }

}