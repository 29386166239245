import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-teacher-lesson-table',
    templateUrl: './teacher-lesson-table.component.html',
    standalone: false
})
export class TeacherLessonTableComponent implements OnInit {

  columns = [	{title: 'title', caption: 'title', filter: 'none', width: '45%'},
          {title: 'views_count', caption: 'views', filter: 'none', width: '15%'},
          {title: 'likes_count', caption: 'likes', filter: 'none', width: '15%'},
  				{title: 'created_at', caption: 'created-at', filter: 'date', width: '25%'}
  			  ];
  displayedColumns = ['title', 'views_count', 'likes_count', 'created_at'];

  constructor() { }

  ngOnInit(): void {
  }

}
