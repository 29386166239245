import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { filter, map, pairwise, throttleTime, timer } from 'rxjs';
import { formatDate } from '@angular/common';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: false
})
export class HomeComponent implements OnInit {

	@ViewChild(CdkVirtualScrollViewport) scroller: CdkVirtualScrollViewport|null = null;

	public items: any[] = [];
	public loading: boolean = false;
	private page: number = 1;
	private now: string = '';

	constructor(private http: HttpClient, private router: Router, private ngZone: NgZone, private meta: Meta) { 
		this.now = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en');
		this.meta.addTags([
			{name: 'description', content: 'Ddolphin Education'},
			{name: 'author', content: 'Ddolphin'},
			{name: 'keywords', content: 'Education, Ddolphin, Learn, Teach, Social'}
		  ]);
	}

	ngOnInit(): void {
		this.fetchMore();
	}

	ngAfterViewInit(){
		this.scroller?.elementScrolled().pipe(
			map(()=>this.scroller?.measureScrollOffset("bottom")),
			pairwise(),
			filter(([y1, y2]) => (y2!<y1!)&&(y2!<140)),
			throttleTime(200)
		).subscribe(()=>{
			this.ngZone.run(()=>{
				this.fetchMore();
			});
		});
	}

	fetchMore():void{
		//prevents from this method being called twice before the previous is finished
		if (this.loading)
			return;
		this.loading = true;
		this.http.get(`${environment.apiURL}/feed?page=${this.page}&datetime=${this.now}`)
        	.subscribe({
				next: (result: any)=>{
					this.items = [...this.items, ...result['data']];
					this.loading = false;
					this.page++;
				},
				error: (error: any)=>{
					this.loading = false;
				}
        	});
		
	}

}
