<mat-spinner *ngIf="is_loading" [diameter]="50"></mat-spinner>
<mat-tab-group (selectedIndexChange)="loadData($event)">
    <mat-tab [label]="'following'|translate">
        <mat-card>
            <mat-card-header>
                <span>
                    <mat-card-subtitle>{{'following-description'|translate}}</mat-card-subtitle>
                </span>
            </mat-card-header>
            <mat-card-content *ngIf="tab==0">
                <mat-card class="mat-elevation-z8" *ngFor="let person of people; index as i;">
                    <mat-card-header>
                        <a [routerLink]="['/'+person.username]">
                            <div class="avatar"
                                [ngClass]="{'avatar-acqua': (person.name+person.surname).length%8==0, 'avatar-beige': (person.name+person.surname).length%8==1, 'avatar-blue': (person.name+person.surname).length%8==2, 'avatar-yellow': (person.name+person.surname).length%8==3, 'avatar-pink': (person.name+person.surname).length%8==4, 'avatar-viole': (person.name+person.surname).length%8==5, 'avatar-orange': (person.name+person.surname).length%8==6, 'avatar-green': (person.name+person.surname).length%8==7}">
                                <img mat-card-avatar [src]="'data:image/jpg;base64,'+person.profile_picture"
                                    *ngIf="person.profile_picture!=null">
                                <div class="avatar-empty" *ngIf="person.profile_picture==null">{{ person.name.charAt(0)
                                    }}{{
                                    person.surname.charAt(0) }}</div>
                            </div>
                            <span class="title">
                                <mat-card-title>{{ person.name }} {{ person.surname }}</mat-card-title>
                                <mat-card-subtitle>&#64;{{ person.username }}, {{'published'|translate|lowercase}} {{ person.lessons_count }} {{'lessons'|translate|lowercase}}
                                    {{'and'|translate}} {{
                                    person.tests_count }} {{'tests'|translate|lowercase}} </mat-card-subtitle>
                            </span>
                        </a>
                    </mat-card-header>
                </mat-card>
                <mat-paginator [length]="total_count" [pageSize]="20"
                    (page)="loadFollowing($event.pageIndex+1)"></mat-paginator>
            </mat-card-content>
        </mat-card>
    </mat-tab>
    <mat-tab [label]="'followers'|translate">
        <mat-card>
            <mat-card-header>
                <mat-card-subtitle>{{'followers-description'|translate}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content *ngIf="tab==1">
                <mat-card class="mat-elevation-z8" *ngFor="let person of people; index as i;">
                    <mat-card-header>
                        <a [routerLink]="['/'+person.username]">
                            <div class="avatar"
                                [ngClass]="{'avatar-acqua': (person.name+person.surname).length%8==0, 'avatar-beige': (person.name+person.surname).length%8==1, 'avatar-blue': (person.name+person.surname).length%8==2, 'avatar-yellow': (person.name+person.surname).length%8==3, 'avatar-pink': (person.name+person.surname).length%8==4, 'avatar-viole': (person.name+person.surname).length%8==5, 'avatar-orange': (person.name+person.surname).length%8==6, 'avatar-green': (person.name+person.surname).length%8==7}">
                                <img mat-card-avatar [src]="'assets/images/users/'+person.profile_picture"
                                    *ngIf="person.profile_picture!=null">
                                <div class="avatar-empty" *ngIf="person.profile_picture==null">{{ person.name.charAt(0)
                                    }}{{
                                    person.surname.charAt(0) }}</div>
                            </div>
                            <span class="title">
                                <mat-card-title>{{ person.name }} {{ person.surname }}</mat-card-title>
                                <mat-card-subtitle>&#64;{{ person.username }}, {{'published'|translate|lowercase}} {{ person.lessons_count }} {{'lessons'|translate|lowercase}}
                                    {{'and'|translate}} {{
                                    person.tests_count }} {{'tests'|translate|lowercase}} </mat-card-subtitle>
                            </span>
                        </a>
                    </mat-card-header>
                </mat-card>
                <mat-paginator [length]="total_count" [pageSize]="20"
                    (page)="loadFollowers($event.pageIndex+1)"></mat-paginator>
            </mat-card-content>
        </mat-card>
    </mat-tab>
    <mat-tab [label]="'request-received'|translate">
        <mat-card>
            <mat-card-header>
                <mat-card-subtitle>{{'requests-received-description'|translate}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content *ngIf="tab==2">
                <mat-card class="mat-elevation-z8" *ngFor="let person of people; index as i;">
                    <mat-card-header>
                        <a [routerLink]="['/'+person.username]">
                            <div class="avatar"
                                [ngClass]="{'avatar-acqua': (person.name+person.surname).length%8==0, 'avatar-beige': (person.name+person.surname).length%8==1, 'avatar-blue': (person.name+person.surname).length%8==2, 'avatar-yellow': (person.name+person.surname).length%8==3, 'avatar-pink': (person.name+person.surname).length%8==4, 'avatar-viole': (person.name+person.surname).length%8==5, 'avatar-orange': (person.name+person.surname).length%8==6, 'avatar-green': (person.name+person.surname).length%8==7}">
                                <img mat-card-avatar [src]="'assets/images/users/'+person.profile_picture"
                                    *ngIf="person.profile_picture!=null">
                                <div class="avatar-empty" *ngIf="person.profile_picture==null">{{ person.name.charAt(0)
                                    }}{{
                                    person.surname.charAt(0) }}</div>
                            </div>
                            <span class="title">
                                <mat-card-title>{{ person.name }} {{ person.surname }}</mat-card-title>
                                <mat-card-subtitle>&#64;{{ person.username }}, {{'published'|translate|lowercase}} {{ person.lessons_count }} {{'lessons'|translate|lowercase}}
                                    {{'and'|translate}} {{
                                    person.tests_count }} {{'tests'|translate|lowercase}} </mat-card-subtitle>
                            </span>
                        </a>
                    </mat-card-header>
                    <mat-card-actions class="vivid-theme">
                        <button type="button" mat-raised-button color="primary" (click)="accept(person?.username)">
                            <mat-icon>how_to_reg</mat-icon> {{'accept'|translate}} 
                        </button>
                        <button type="button" mat-raised-button color="warn" (click)="deny(person?.username)">
                            <mat-icon>person_remove</mat-icon> {{'deny'|translate}} 
                        </button>
                    </mat-card-actions>
                </mat-card>
                <mat-paginator [length]="total_count" [pageSize]="20"
                    (page)="loadFollowers($event.pageIndex+1)"></mat-paginator>
            </mat-card-content>
        </mat-card>
    </mat-tab>
    <mat-tab [label]="'request-sent'|translate">
        <mat-card>
            <mat-card-header>
                <span>
                    <mat-card-subtitle>{{'request-sent-description'|translate}}</mat-card-subtitle>
                </span>
            </mat-card-header>
            <mat-card-content *ngIf="tab==3">
                <mat-card class="mat-elevation-z8" *ngFor="let person of people; index as i;">
                    <mat-card-header>
                        <a [routerLink]="['/'+person.username]">
                            <div class="avatar"
                                [ngClass]="{'avatar-acqua': (person.name+person.surname).length%8==0, 'avatar-beige': (person.name+person.surname).length%8==1, 'avatar-blue': (person.name+person.surname).length%8==2, 'avatar-yellow': (person.name+person.surname).length%8==3, 'avatar-pink': (person.name+person.surname).length%8==4, 'avatar-viole': (person.name+person.surname).length%8==5, 'avatar-orange': (person.name+person.surname).length%8==6, 'avatar-green': (person.name+person.surname).length%8==7}">
                                <img mat-card-avatar [src]="'assets/images/users/'+person.profile_picture"
                                    *ngIf="person.profile_picture!=null">
                                <div class="avatar-empty" *ngIf="person.profile_picture==null">{{ person.name.charAt(0)
                                    }}{{
                                    person.surname.charAt(0) }}</div>
                            </div>
                            <span class="title">
                                <mat-card-title>{{ person.name }} {{ person.surname }}</mat-card-title>
                                <mat-card-subtitle>&#64;{{ person.username }}, {{'published'|translate|lowercase}} {{ person.lessons_count }} {{'lessons'|translate|lowercase}}
                                    {{'and'|translate}} {{
                                    person.tests_count }} {{'tests'|translate|lowercase}} </mat-card-subtitle>
                            </span>
                        </a>
                    </mat-card-header>
                </mat-card>
                <mat-paginator [length]="total_count" [pageSize]="20"
                    (page)="loadAwaiting($event.pageIndex+1)"></mat-paginator>
            </mat-card-content>
        </mat-card>
    </mat-tab>
    <mat-tab [label]="'blocked'|translate">
        <mat-card>
            <mat-card-header>
                <mat-card-subtitle>{{'blocked-description'|translate}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content *ngIf="tab==4">
                <mat-card class="mat-elevation-z8" *ngFor="let person of people; index as i;">
                    <mat-card-header>
                        <a [routerLink]="['/'+person.username]">
                            <div class="avatar"
                                [ngClass]="{'avatar-acqua': (person.name+person.surname).length%8==0, 'avatar-beige': (person.name+person.surname).length%8==1, 'avatar-blue': (person.name+person.surname).length%8==2, 'avatar-yellow': (person.name+person.surname).length%8==3, 'avatar-pink': (person.name+person.surname).length%8==4, 'avatar-viole': (person.name+person.surname).length%8==5, 'avatar-orange': (person.name+person.surname).length%8==6, 'avatar-green': (person.name+person.surname).length%8==7}">
                                <img mat-card-avatar [src]="'assets/images/users/'+person.profile_picture"
                                    *ngIf="person.profile_picture!=null">
                                <div class="avatar-empty" *ngIf="person.profile_picture==null">{{ person.name.charAt(0)
                                    }}{{
                                    person.surname.charAt(0) }}</div>
                            </div>
                            <span class="title">
                                <mat-card-title>{{ person.name }} {{ person.surname }}</mat-card-title>
                                <mat-card-subtitle>&#64;{{ person.username }}, {{'published'|translate|lowercase}} {{ person.lessons_count }} {{'lessons'|translate|lowercase}}
                                    {{'and'|translate}} {{
                                    person.tests_count }} {{'tests'|translate|lowercase}} </mat-card-subtitle>
                            </span>
                        </a>
                    </mat-card-header>
                </mat-card>
                <mat-paginator [length]="total_count" [pageSize]="20"
                    (page)="loadBlocked($event.pageIndex+1)"></mat-paginator>
            </mat-card-content>
        </mat-card>
    </mat-tab>
</mat-tab-group>