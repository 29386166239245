import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.css'],
    standalone: false
})
export class EmailVerificationComponent implements OnInit {

  public isLoadingResults: boolean = true;
  public error: boolean = false;
  public alreadyActivated = false;

  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  ngOnInit(): void {
    let token = this.route.snapshot.paramMap.get('token');
    this.http.post(`${environment.apiURL}/verify-email`, {token: token}).subscribe({
      next: (response:any)=>{
        this.alreadyActivated = response.data;
        this.isLoadingResults = false;
      }, error: error=>{
        this.error = true;
        this.isLoadingResults = false;
      }
    });
  }

}
