import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ConfirmationDialogModel, ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Capacitor } from '@capacitor/core';

import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

//for tinyMCE and Wiris Math editor
const jsDemoImagesTransform = document.createElement('script');
jsDemoImagesTransform.type = 'text/javascript';
jsDemoImagesTransform.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
document.head.appendChild(jsDemoImagesTransform);

const platform = Capacitor.getPlatform();

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css'],
    standalone: false
})
export class FormComponent implements OnInit {

	//the fields of the form, must be an array of JSON, containing: type, label, required, defaultValue and other properties
	@Input() items: FormItem[] = [];
	/*options to guide backend URL and etc
	mandatory fields: 
	url: the backend url (to be added to the environment url)
	paramField: what field will be used as parameter from the address bar. most comon values would be 'id' and 'uuid'
	title: to be displayed at the top of the form
	deleteMessage: message to be displayed by the confiemation form
	returnLink: link that the return button will point to
	editLink: link to redirect after saving a new registry
	saveButtonCaption: the caption for the save button, only used when creating new items
	saveButtonIcon: the icon for the save button, only used when creating new items
	extraButtons: in some forms you may need extra buttons, this is an array of objects for the new buttons you need, their attrs are:
	  caption icon link color
	*/
	@Input() options: any;

	//it will detect the uuid from the address bar
	public paramValue: string | null = null;
	//it will compose the backend url 
	public url: string = '';
	public form = new UntypedFormGroup({});
	public isWaitingResults: boolean = false;
	public isLoadingResults: boolean = false;

	public editorOptions: Object = {
		base_url: '/tinymce', // Root for resources
		suffix: '.min',        // Suffix to use when loading resources
		external_plugins: {'tiny_mce_wiris': `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`},
		htmlAllowedTags: ['.*'],
		htmlAllowedAttrs: ['.*'],
		extended_valid_elements: '*[.*]',
		draggable_modal: true,
		plugins: ['autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'table', 'visualblocks', 'wordcount'],
		//disabling editing of math/chemestry and code on mobiles as it causes malfunction and crash
		toolbar: platform=='web' ? 
					'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image table | codesample tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | align | numlist bullist indent outdent | emoticons charmap | removeformat' :
					'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image table | align | numlist bullist indent outdent | emoticons charmap | removeformat',
		menubar: 'edit insert format table tools'
	};

	constructor(private formBuilder: UntypedFormBuilder, private http: HttpClient, private route: ActivatedRoute, private router: Router,
		private dialog: MatDialog,
		private toastr: ToastrService,
		private translate: TranslateService) { }

	ngOnInit(): void {
		this.url = environment.apiURL + this.options['url'];

		//building up the form
		let formGroup: any = {};
		//each member of items is a field
		this.items.forEach((item: any) => {
			//determining if defaultValue is empty or if something was passed as option
			let val = item.defaultValue ? item.defaultValue : '';
			let validators = item.required ? [Validators.required] : [];
			//allowing custom validators
			if (item.validators) {
				item.validators.forEach((validator: any) => {
					validators.push(validator);
				});
			}
			formGroup[item.name] = [val, validators];
		});
		this.form = this.formBuilder.group(formGroup);

		//reading the value from the addressbar, and if any loads the values from the backend
		//if there's no value means it's in a "creating new" form
		this.paramValue = this.route.snapshot.paramMap.get(this.options['paramField']);
		if (this.paramValue) {
			this.isLoadingResults = true;
			this.http.get(this.url + this.paramValue, { responseType: 'json' })
				.subscribe({
					next: (response: any) => {
						this.form.patchValue(response['data']);
						this.isLoadingResults = false;
					},
					error: error => this.isLoadingResults = false
				});
		}
	}

	onSubmit() {
		this.isWaitingResults = true;
		//when submiting and it has a paramValue, means that should be an update
		//if no paramValue should be a post request
		let request = this.paramValue ?
			this.http.put(this.url + this.paramValue,
				JSON.stringify(this.form.getRawValue()),
				{ responseType: 'json' }) :
			this.http.post(this.url,
				JSON.stringify(this.form.getRawValue()),
				{ responseType: 'json' });
		request
			.subscribe({
				next: (response: any) => {
					//if there's no paramValue, redirects to the editing page of the newly created item
					if (!this.paramValue) {
						this.router.navigate([this.options['editLink'] + response['data']]);
						//else just updates the form with the values received from the backend
					} else {
						this.form.patchValue(response['data']);
					}
					this.isWaitingResults = false;
					this.toastr.success(this.translate.instant(this.options['saveMessage']), this.translate.instant('success'));
				},
				error: error => this.isWaitingResults = false
			});
	}

	//confirming before deleting
	confirmAndRemove() {
		const message = this.options['deleteConfirmation'];
		const dialogData = new ConfirmationDialogModel(this.translate.instant('confirm-action'), this.translate.instant(message));
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			data: dialogData,
			backdropClass: 'backdrop-background',
			hasBackdrop: true
		});
		dialogRef.updatePosition({ top: "10%" });

		dialogRef.afterClosed().subscribe(dialogResult => {
			if (dialogResult) {
				//if clicked yes, then attempts a delete request to the backend
				this.http.delete(this.url + this.paramValue, { responseType: 'json' })
					.subscribe(response => {
						//if deleted redirects back to the table
						this.router.navigate([this.options['returnLink']]);
						this.toastr.success(this.translate.instant(this.options['deleteMessage']), this.translate.instant('success'));
					})
			} else
				this.toastr.warning(this.translate.instant('action-cancelled'), this.translate.instant('no-action'));
		});
	}

	customBtnClick(link: string) {
		let finalLink = link.replace(`{${this.options.paramField}}`, this.paramValue as string);
		console.log(finalLink);
		this.router.navigate([]).then(() => {
			window.open(finalLink, '_blank');
		});
	}

}

//mandatory fields of each item
/*
label is the text to be visible to the user, required pretty much for every coponent
name is the variable name, it'll compose the JSON to be send to the backend
type: select, input, text-area, number
required: true or false
defaultValue: useful for selects
options: select options
placeholder: placeholder of input and textareas
autocomplete: "off" to disable html autocomplete
hint: text that will compose the material-hint
rows: for text areas
min/max: min and max for numbers
*/
export class FormItem {
	label: string = '';
	name: string = '';
	type: string = '';
	required: boolean = false;
	defaultValue: any;
	options: any;
	placeholder: string = '';
	autocomplete: string = '';
	hint: string = '';
	rows: number = 5;
}