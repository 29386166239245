import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FriendshipService } from '../../services/friendship.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css'],
    standalone: false
})
export class SearchComponent implements OnInit {

  people = [];
  lessons = [];
  tests = [];
  searchVal = '';
  total_count: number = 0;
  is_loading = true;

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private toastr: ToastrService, 
              private friendshipService: FriendshipService, private translate: TranslateService) { 
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.searchVal = this.route.snapshot.paramMap.get('search')||'';      
    this.searchPeople(1);
  }

  follow(index: number){
    const person: any = this.people[index];
    this.friendshipService.follow(person.uuid)
      .subscribe(result=>{
        person.awaiting_approval = person.private_account ? true : false;
        this.toastr.success(this.translate.instant('added-2-following'), `${this.translate.instant('success')}!`);
      });
  }

  unfollow(index: number){
    this.friendshipService.confirmUnfollowing()
      .subscribe(dialogResult=>{
        if (dialogResult){
          const person: any = this.people[index];
          this.friendshipService.unfollow(person.uuid)
            .subscribe(result=>{
              person.awaiting_approval = null;
              this.toastr.success(this.translate.instant('removed-from-following'), `${this.translate.instant('success')}!`);
            });
        } else
          this.toastr.warning('Action canceled!', 'No Action');
      });
  }

  searchPeople(page: number){
    if (this.searchVal.trim().length>0)
      this.http.get(`${environment.apiURL}/search/people?search=${this.searchVal}&page=${page}`)
        .subscribe((result: any)=>{
          this.people = result['data']['people'];
          this.total_count = result['data']['total_count'];
          this.is_loading = false;
        });
  }

  searchLessons(page: number){
    if (this.searchVal.trim().length>0)
      this.http.get(`${environment.apiURL}/search/lessons?search=${this.searchVal}&page=${page}`)
        .subscribe((result: any)=>{
          this.lessons = result['data']['lessons'];
          this.total_count = result['data']['total_count'];
          this.is_loading = false;
        });
  }

  searchTests(page: number){
    if (this.searchVal.trim().length>0)
      this.http.get(`${environment.apiURL}/search/tests?search=${this.searchVal}&page=${page}`)
        .subscribe((result: any)=>{
          this.tests = result['data']['tests'];
          this.total_count = result['data']['total_count'];
          this.is_loading = false;
        });
  }

  loadData(tab: number){
    this.is_loading = true;
    switch (tab) {
      case 0:
        this.searchPeople(1);
        break;
      case 1:
        this.searchLessons(1);
        break;
      case 2:
        this.searchTests(1);
        break
      default:
        break;
    }
  }

}
