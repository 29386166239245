import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {


  constructor(private router: Router, private toastr: ToastrService, private translate: TranslateService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      //adding the token from localStorage to the headers
      const token = localStorage.getItem('auth-token');
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
      
      //finalizing the headers that are common to the entire application
      //sometimes (picture uploads) cont-type will be set before reaching this interceptor, then has to skip....otherwise sets it to json
      if (!request.headers.has('Content-Type'))
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      //for some reason to submit files have to remove COntent-Type headers
      //so if it's set at the component to form-data, then it's removed here
      else if (request.headers.get('Content-Type')=='multipart/form-data')
        request = request.clone({headers: request.headers.delete('Content-Type')});
      //asks the backend to return a json
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

      //if the return from the server is 401, redirects to login....if not continues to the page
      return next.handle(request).pipe(tap({
        next: (event: HttpEvent<any>)=>{}, 
        error: (err: any)=>{
          if (err instanceof HttpErrorResponse)
            //error 401 is peculiar because when dispached when attempting to login or to sign the token (url+/user), it shouldn't show the toastr
            if (err.status==401){
              //only redirects and clears localStorage if it's not coming from login page
              if (request.url!=`${environment.apiURL}/login`) {
                if ((request.url!=`${environment.apiURL}/fcm-token`)&&(request.url!=`${environment.apiURL}/logout`)) {
                  localStorage.removeItem("auth-token");
                  this.router.navigate(['login']);
                }
              }
              return;
            }

            switch (err.status) {
              case 403:
                var message = this.translate.instant('http.e403');
                break;
              case 404:
                var message = this.translate.instant('http.e404');
                break;
              case 405:
                var message = this.translate.instant('http.e405');
                break;
              case 409:
                var message = this.translate.instant('http.e409');
                break;
              case 422:
                var message = this.translate.instant('http.e422');
                break;
              default:
                var message = this.translate.instant('http.error');
                break;
            }
            this.toastr.error(message, `${this.translate.instant('error')}: ${err.status}`);
          }
      }));
    }
}