import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective,Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SignupValidator } from 'src/app/validators/signup.validators';


import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
const moment = _rollupMoment || _moment;

import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
    standalone: false
})
export class ProfileComponent implements OnInit {

  //still have to think a better way to do this
  //atm it's being converted from the api to JS date
  //and then before sending to the api is being converted again  
  dob = new UntypedFormControl(moment());

	form = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required, Validators.minLength(3), SignupValidator.usernameFormatValidator]),
		name: new UntypedFormControl('', [Validators.required]),
    surname: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    language: new UntypedFormControl(''),
    about_me: new UntypedFormControl(''),
    private_account: new UntypedFormControl()
	});

  formPwd = new UntypedFormGroup({
    old_password: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required, SignupValidator.passwordStrengthValidator]),
    password_confirmation: new UntypedFormControl(),
  }, { validators: SignupValidator.passwordsMatch()});

  formDel = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required]),
  });

  selectedPicture: File|null = null;
	user: any;
  public isLoadingResults: boolean = true;
  public isUploadingPicture: boolean = false;
       
       public options: Object = {
           base_url: '/tinymce', // Root for resources
           suffix: '.min',        // Suffix to use when loading resources
           htmlAllowedTags:  ['.*'],
           htmlAllowedAttrs: ['.*'],
           extended_valid_elements: '*[.*]',
           draggable_modal: true,
           plugins: ['autolink', 'charmap', 'emoticons', 'lists', 'visualblocks', 'wordcount'],
           toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align | numlist bullist indent outdent | emoticons charmap | removeformat',
           menubar: 'edit insert format table tools'
       };

  constructor(private http: HttpClient, private toastr: ToastrService, private translate: TranslateService, private authService: AuthService,
              private profileService: ProfileService) { 
  }

  ngOnInit(): void {
  	this.http.get(`${environment.apiURL}/profile`)
  		.subscribe((result: any)=>{
  			this.user = result['data'];
        this.dob.setValue(result['data'].dob);
        this.form.patchValue(this.user);
        this.isLoadingResults = false;
  		});
  }

  onPictureSelect(event: any){
    this.isUploadingPicture = true;
  	const formData = new FormData();
  	this.selectedPicture = <File>event.target.files[0];
  	formData.append('picture', this.selectedPicture, this.selectedPicture.name);
    let header = new HttpHeaders({'Content-Type': 'multipart/form-data'});
  	this.http.post(`${environment.apiURL}/profile/picture`, formData, {headers: header})
  		.subscribe((result: any)=>{
        this.user['profile_picture'] = result['data'];
  			this.toastr.success(this.translate.instant('picture-updates'), `${this.translate.instant('success')}!`);
        this.isUploadingPicture = false;
  		});
  }

  onSubmitProfile(){
    //if form isn't valid then won't submit it
    if (!this.form.valid)
      return;

    var formData = this.form.getRawValue();
    formData['dob'] = moment(this.dob.value, 'YYYY-MM-DD').format('YYYY-MM-DD');
  	this.http.put(`${environment.apiURL}/profile`, formData)
  		.subscribe(result=>{
        this.authService.signToken();
  			this.toastr.success(`${this.translate.instant('data-saved')}!`, `${this.translate.instant('success')}!`)
  		});
  }

  onSubmitPassword(){
    if (!this.formPwd.valid)
      return;
    this.http.patch(`${environment.apiURL}/profile/password`, this.formPwd.getRawValue())
      .subscribe(result=>{
        this.toastr.success(this.translate.instant('password-changed'), `${this.translate.instant('success')}!`)
      });
  }

  checkUsernameAvailability(){
    const username = this.form.controls['username'];
    if (username.errors && !username.errors.alreadyTaken)
        return;

    this.http.get(`${environment.apiURL}/profile/username-availability?username=${username.value}`)
      .subscribe((result: any)=>{
        //if data is null or false from the server, then username is not available and sets error to already alreadyTaken
        //if comes true, it means it's available and sets errors to null
        username.setErrors(result['data'] ? null : {alreadyTaken: true});
      });   
  }

  downloadData(){
    this.http.get(`${environment.apiURL}/profile/download-data`).subscribe((response:any)=>{
      let downloadData = [];
      downloadData.push(JSON.stringify(response.data)); 
      let url = window.URL.createObjectURL(new Blob(downloadData));
      let downloadLink = document.createElement('a');
      downloadLink.href = url
      downloadLink.setAttribute('download', 'my-ddolphin-data.txt');
      downloadLink.click();
    });
  }

  deactivate(){
    this.profileService.confirmDeactivate()
        .subscribe(dialogResult=>{
          if (dialogResult)
            this.http.post(`${environment.apiURL}/profile/deactivate`, this.formDel.getRawValue()).subscribe((response:any)=>{
              this.authService.logout(true);
            });
          else 
            this.toastr.warning(this.translate.instant('action-cancelled'), this.translate.instant('no-action'));
        });
  }

}
